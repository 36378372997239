import { baseURL } from "@/api/config";
import { $t } from "@/i18n";
import { type RemovableRef, useStorage } from "@vueuse/core";
import { type Ref, type UnwrapRef } from "vue";
import { RIDE_STATUS, type TRideStatusKey } from "./globalTypes";

// #region Bounce / Throttle
export function debounce(func, waitTimer, immediate) {
	let timeout = null;
	return function () {
		// eslint-disable-next-line unicorn/no-this-assignment
		const context = this;
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}

export function throttle(func, waitTimer = 500) {
	let inThrottle = false;
	return function () {
		const args = arguments;
		// eslint-disable-next-line unicorn/no-this-assignment
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
			}, waitTimer);
		}
	};
}
// #endregion

// #region Simple crypt / decrypt
export function simpleCrypt(salt: string, text: string) {
	const textToChars = (text: string) =>
		text.split("").map((c) => c.codePointAt(0));
	const byteHex = (n: any) => ("0" + Number(n).toString(16)).slice(-2);
	const applySaltToChar = (code: any) =>
		textToChars(salt).reduce((a: any, b: any) => a ^ b, code);

	return text
		.split("")
		.map((element) => textToChars(element))
		.map((element) => applySaltToChar(element))
		.map((element) => byteHex(element))
		.join("");
}
export function simpleDecrypt(salt: string, encoded: string) {
	const textToChars = (text: any) =>
		text.split("").map((c: any) => c.codePointAt(0));
	const applySaltToChar = (code: any) =>
		textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
	return encoded
		.match(/.{1,2}/g)
		.map((hex) => parseInt(hex, 16))
		.map((element) => applySaltToChar(element))
		.map((charCode) => String.fromCodePoint(charCode))
		.join("");
}
// #endregion

export function focusFirstElement(
	parentEl: HTMLElement,
	isFocus = true,
	isDebug = false,
) {
	// Don't invoke more than once (Before a component is destroyed)
	if (parentEl) {
		const inputListToIncludeFocusable = [
			"input:not(:disabled):not(.hidden)",
			"textarea:not(:disabled):not(.hidden)",
			"button:not(:disabled):not(.hidden)",
			"span.focusable",
			"div.focusable",
			".multiselect[tabindex]",
			"*[tabindex]:not(:disabled):not(.hidden)",
		].join(",");

		const nodeList = Array.from(
			parentEl.querySelectorAll(inputListToIncludeFocusable),
		) as HTMLElement[];
		if (nodeList?.length) {
			const addInputTabHandling = (nodeList: HTMLElement[]) => {
				const focusEl = (evt: KeyboardEvent, oppoEl: HTMLElement) => {
					// Only for first / last element
					oppoEl.focus();
					evt.preventDefault();
				};

				// First el
				nodeList[0].addEventListener("keydown", (evt: KeyboardEvent) => {
					if (evt.key === "Tab" && evt.shiftKey) {
						focusEl(evt, nodeList.at(-1) as any);
					}
				});

				// Last el
				(nodeList.at(-1) as any).addEventListener(
					"keydown",
					(evt: KeyboardEvent) => {
						if (evt.key === "Tab" && !evt.shiftKey) {
							focusEl(evt, nodeList[0]);
						}
					},
				);
			};

			if (isFocus) {
				// console.log("➕ Focusing first el", nodeList[0]);
				nodeList[0].focus();

				if (isDebug) {
					console.error("➕ Focusing first el", nodeList[0]);
				}
			}

			addInputTabHandling(nodeList);
		} else if (isDebug) {
			console.warn("No child element found for focus");
		}
	} else if (isDebug) {
		console.warn("No parent element found for focus");
	}
}

export function getCurrentDomain() {
	let url = new URL(location.href);
	try {
		url = new URL(baseURL);
	} catch {
		// ignored - could break on prod because link is not valid
	}
	return url;
}
function getUseStorage<T>(
	key: string,
	shouldParse = false,
	storage: Storage,
	defaultVal: T | null = null, // Undefined is broken (creates invalid value in store)
): Ref<T | UnwrapRef<T> | string | null | undefined> {
	const state = useStorage(key, defaultVal, storage);
	if (!shouldParse) return state;

	try {
		return ref(JSON.parse(state.value as string));
	} catch {
		console.error("Error loading key", key);
		return ref(defaultVal);
	}
}

export function getLocalStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, localStorage, defaultVal);
}

export function getSessionStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, sessionStorage, defaultVal);
}

export function setLocalStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, localStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (localStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		localStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function setSessionStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, sessionStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (sessionStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		sessionStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export const InfoConsole = {
	/**
	 * Console log with colors
	 */
	l(msg: string, ...payload: any[]) {
		console.log(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console warn with colors
	 */
	w(msg: string, ...payload: any[]) {
		console.warn(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console error with colors
	 */
	e(msg: string, ...payload: any[]) {
		console.error(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
};

export function rideStatusText(status: TRideStatusKey): string | null {
	switch (status) {
		case RIDE_STATUS.OPEN: {
			return $t("app.ride-status.open");
		}
		case RIDE_STATUS.FINISHED: {
			return $t("app.ride-status.finished");
		}
		case RIDE_STATUS.CANCELED: {
			return $t("app.ride-status.canceled");
		}
		case RIDE_STATUS.AWAITING_USER: {
			return $t("app.ride-status.awaiting-user");
		}
		case RIDE_STATUS.AWAITING_DRIVER: {
			return $t("app.ride-status.awaiting-driver");
		}
		case RIDE_STATUS.CANCELED_DRIVER: {
			return $t("app.ride-status.canceled-driver");
		}
		case RIDE_STATUS.EXPIRED: {
			return $t("app.ride-status.expired");
		}
		default: {
			return null;
		}
	}
}

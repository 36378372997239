<script setup lang="ts">
definePage({
	name: "LoginPage",
	meta: {
		name: "Login",
	},
});
</script>

<template>
	<div id="login-page">
		<div class="comp-section">
			<login-comp></login-comp>
		</div>
	</div>
</template>

<style lang="scss" scoped>
#login-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;

	.image-section,
	.comp-section {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.image-section {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;

		.title {
			font-family: Anton, sans-serif;
			font-size: clamp(1rem, 4vw, 60px);
			font-style: normal;
			font-weight: 400;
		}

		.desc {
			font-family: "Open Sans", sans-serif;
			font-size: clamp(0.8rem, 3.5vw, 24px);
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		img {
			width: 100%;
			max-width: 900px;
		}
	}

	@media screen and (width > 768px) {
		flex-direction: row;

		.image-section {
			flex-basis: 80%;
		}
		.comp-section {
			min-width: 400px;

			> * {
				width: 100%;
			}
		}
	}
}
</style>

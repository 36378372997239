<script setup lang="ts">
import api from "@/api";
import { baseURL } from "@/api/config";
import { DateInstance } from "@/assets/js/dateHelper";
import type { TDriver, TRide, TRideStatusKey } from "@/assets/js/globalTypes";
import { rideStatusText } from "@/assets/js/helpers";
import { openConfirmModal } from "@/components/modals/modalDefinitions";
import { $t } from "@/i18n";
import { useRoute } from "vue-router";
definePage({
	name: "SingleTaxiPage",
	meta: {
		requiresLogin: true,
	},
});

const route = useRoute();
const tax_id = computed(() => Number(route.params.id) || 0);

const driver = ref<TDriver | null>(null);
const rides = ref<TRide[]>();
const current_ride = ref<TRide>();
const statistics = ref<{ [k: string]: any }>();

const edit_credits = ref(false);
const driver_credits = ref(0);

function toggleEditCredits() {
	edit_credits.value = true;
	driver_credits.value = driver.value?.tax_remaining_credit || 0;
}

watch(
	tax_id,
	() => {
		getDriverInfo();
		getDriverStatistics();
	},
	{ immediate: true },
);

async function getDriverInfo() {
	try {
		const res = await api.getDriverInfo({ tax_id: tax_id.value });
		driver.value = res.data.data.driver;
		current_ride.value = res.data.data.current_ride;
		rides.value = res.data.data.rides;
	} catch (error) {
		console.log(error);
	}
}

async function getDriverStatistics() {
	try {
		const res = await api.getDriverStatistics(tax_id.value);
		statistics.value = res.data.data;
	} catch (error) {
		console.log(error);
	}
}

async function editCredits() {
	try {
		await api.editDriver({
			tax_id: tax_id.value,
			tax_credits: driver_credits.value,
		});
		edit_credits.value = false;
		getDriverInfo();
	} catch (error) {
		console.log(error);
	}
}

async function verifyDriver() {
	try {
		await api.editDriver({
			tax_id: tax_id.value,
			tax_privilege: 10,
		});
		getDriverInfo();
	} catch (error) {
		console.log(error);
	}
}

function getRideStatus(ride_status: TRideStatusKey) {
	return rideStatusText(ride_status);
}

function handleBanClick() {
	const name = driver?.value.tax_name + " " + driver?.value.tax_lastname;
	openConfirmModal(
		{
			name,
		},
		() => {
			api.banDriver(tax_id.value);
		},
		$t("app.actions.ban-confirm", { name }),
	);
}

async function downloadFile(file_id: number | null | undefined) {
	if (!file_id) {
		throw new Error("invalid-file-id");
	}
	try {
		let res = await api.downloadDocument(file_id);
		const blob = res.data;

		let headerLine = res.headers["content-disposition"];
		let startFileNameIndex = headerLine.indexOf('"') + 1;
		let endFileNameIndex = headerLine.lastIndexOf('"');
		let filename = headerLine.slice(startFileNameIndex, endFileNameIndex);

		const fake_link = document.createElement("a");
		const url = window.URL.createObjectURL(blob);
		fake_link.href = url;
		fake_link.target = "_blank";
		fake_link.download = filename;
		document.body.append(fake_link);
		fake_link.click();
		fake_link.remove();
		window.URL.revokeObjectURL(url);
	} catch (e) {
		console.log(e);
	}
}
</script>
<template>
	<div id="taxi-page-single">
		<div class="header-wrap">
			<div class="avatar">
				<div class="img-wrap">
					<img
						:src="baseURL + driver?.image"
						alt=""
					/>
				</div>
				<h2>{{ driver?.tax_name }} {{ driver?.tax_lastname }}</h2>
				<p>
					<strong>
						{{
							driver?.tax_privilege
								? $t("app.driver.verified")
								: $t("app.driver.unverified")
						}}
					</strong>
				</p>
			</div>
			<div class="avatar">
				<div class="img-wrap">
					<img
						:src="baseURL + driver?.tax_car_image"
						alt=""
					/>
				</div>
				<h2>{{ driver?.tax_model }}</h2>
				<p>
					{{ $t("app.driver.plates") }}:
					<strong>{{ driver?.tax_plates }}</strong>
				</p>
			</div>
		</div>
		<div class="driver-info">
			<section>
				<p>
					{{ $t("app.driver.status") }}:
					<strong>
						{{
							driver?.tax_active
								? $t("app.driver.active")
								: $t("app.driver.inactive")
						}}
					</strong>
				</p>
				<p>
					{{ $t("app.auth.email") }}:
					<strong>{{ driver?.tax_email }}</strong>
				</p>
				<p>
					{{ $t("app.driver.assotiation") }}:
					<strong>{{ driver?.aso_name }}</strong>
				</p>
				<p>
					{{ $t("app.driver.phone") }}:
					<strong>{{ driver?.tax_phone }}</strong>
				</p>
			</section>
			<section>
				<router-link
					v-if="current_ride"
					:to="{ name: 'SingleRidePage', params: { id: current_ride.rid_id } }"
				>
					🟢 {{ $t("app.ride-actions.in-ride") }}
				</router-link>
				<span v-else>🔴 {{ $t("app.ride-actions.not-in-ride") }}</span>
			</section>
			<section
				v-if="!edit_credits"
				class="credits-header"
			>
				<h3>
					{{ $t("app.driver.remaining-credits") }}:
					<strong>{{ driver?.tax_remaining_credit || 0 }}</strong>
				</h3>
				<img
					src="@/assets/image/icons/edit.svg"
					alt=""
					class="icon"
					@click="toggleEditCredits"
				/>
			</section>
			<section
				v-else
				class="credits-header"
			>
				<div class="input-wrap">
					<modern-input
						v-model="driver_credits"
						label=""
						:bind="{ type: 'number' }"
						@keyup.enter="editCredits"
					/>
					<img
						src="@/assets/image/icons/close.svg"
						class="icon"
						alt=""
						@click="edit_credits = false"
					/>
				</div>
				<button
					class="alt"
					@click="driver_credits += 500"
				>
					+500
				</button>
				<button
					class="alt"
					@click="driver_credits += 1000"
				>
					+1000
				</button>
				<button
					class="alt"
					@click="driver_credits += 3000"
				>
					+3000
				</button>
				<button @click="editCredits">
					{{ $t("app.actions.save") }}
				</button>
			</section>
			<section>
				<p v-if="rides && rides[0]">
					{{ $t("app.driver.last-ride-date") }}:
					<strong>
						{{
							DateInstance.getLocalTimeFromISO(
								rides[0].rid_request_time,
								"HH:mm DD.MM.YYYY",
							)
						}}
					</strong>
				</p>
				<p>
					{{
						rides && rides.length > 0
							? $t("app.home.last-five-rides") + ":"
							: $t("app.ride-actions.no-rides")
					}}
				</p>
				<div
					v-for="ride in rides"
					:key="ride.rid_id"
				>
					<router-link
						:to="{ name: 'SingleRidePage', params: { id: ride.rid_id } }"
					>
						{{ $t("app.ride-actions.ride-id") }}: {{ ride.rid_id }}
					</router-link>
					<span>&nbsp;- {{ getRideStatus(ride.rid_status) }}</span>
				</div>
			</section>
			<section style="display: flex; gap: 10px">
				<button
					v-if="!driver?.tax_privilege"
					@click="verifyDriver"
				>
					{{ $t("app.actions.verify") }}
				</button>
				<button
					class="alt"
					@click="handleBanClick"
				>
					{{ $t("app.actions.ban") }}
				</button>
			</section>
		</div>
		<div class="licence-wrap">
			<h2>{{ $t("app.driver.licence-and-registration") }}</h2>
			<div class="licence-content">
				<button @click="downloadFile(driver.tax_licence)">
					{{ $t("app.taxi.download-licence") }}
				</button>
				<button @click="downloadFile(driver.tax_registration)">
					{{ $t("app.taxi.download-registration") }}
				</button>
			</div>
		</div>
		<div class="statistic-wrap">
			<h2>{{ $t("app.taxi.rides-last-30-days") }}:</h2>
			<p>
				{{ $t("app.labels.total") }}:
				<strong>{{ statistics?.total || 0 }}</strong>
			</p>
			<p>
				{{ $t("app.ride-status.finished") }}:
				<strong>{{ statistics?.finished_rides || 0 }}</strong>
			</p>
			<p>
				{{ $t("app.ride-status.canceled") }}:
				<strong>{{ statistics?.canceled_user || 0 }}</strong>
			</p>
			<p>
				{{ $t("app.ride-status.canceled-driver") }}:
				<strong>{{ statistics?.canceled_driver || 0 }}</strong>
			</p>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.parent {
	display: grid;
}

#taxi-page-single {
	padding: 30px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, auto);
	grid-column-gap: 10px;
	grid-row-gap: 25px;
	grid-template-areas:
		"header-wrap driver-info"
		"licence-wrap statistic-wrap";
	.credits-header {
		display: flex;
		gap: 5px;
		align-items: center;
		.input-wrap {
			display: flex;
			align-items: center;
		}
	}
	.header-wrap {
		grid-area: header-wrap;
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		.avatar {
			max-width: max(300px, 20vw);
			display: flex;
			flex-direction: column;
			.img-wrap {
				flex: 1 1;
			}
			img {
				aspect-ratio: 1;
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}
	.driver-info {
		grid-area: driver-info;
		section {
			margin-bottom: 10px;
		}
		a {
			color: $app-accent-color1;
			font-weight: 600;
			font-size: 1.02rem;
		}
	}
	.licence-wrap {
		grid-area: licence-wrap;
		.licence-content {
			margin-top: 20px;
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
		}
	}
	h2 {
		font-weight: 600;
		font-size: 1.3rem;
	}
	.statistic-wrap {
		grid-area: statistic-wrap;
	}

	@media screen and (width <= 768px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(4, auto);
		grid-template-areas:
			"header-wrap"
			"driver-info"
			"licence-wrap"
			"statistic-wrap";
	}
}
</style>

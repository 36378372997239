<script setup lang="ts">
definePage({
	name: "StatisticsPage",
	meta: {
		requiresLogin: true,
	},
});
</script>

<template><div id="statistic-page">asd</div></template>

<style lang="scss" scoped>
#statistic-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	user-select: none;
	flex: 1;
	.banner-section {
		flex: 1;
	}
}
</style>

export type TObjEntries<T> = {
	[K in keyof T]: [K, T[K]];
}[keyof T][];

export type TDriver = {
	tax_name: string;
	tax_lastname: string;
	tax_email: string;
	tax_ac?: 1 | 0;
	tax_card?: 1 | 0;
	tax_longitude: number | null;
	tax_latitude: number;
	tax_phone: string;
	reg_id?: number;
	tax_registration: number;
	tax_id: number;
	tax_wifi?: 1 | 0;
	tax_active?: 1 | 0;
	tax_plates: string;
	tax_grace?: string;
	tax_car_image?: number;
	aso_id?: number;
	image?: string;
	tax_model?: string;
	tax_pets?: 1 | 0;
	tax_smoke?: 1 | 0;
	tax_remaining_credit?: number;
	tax_discount?: number;
	tax_privilege: number;
	tax_licence: number;
	aso_name?: string;
};

export type TDriverStatus = "all" | "active" | "inride" | "inactive";

export const RIDE_STATUS = {
	OPEN: 0,
	ACTIVE: 1,
	FINISHED: 2,
	CANCELED: 3,
	AWAITING_USER: 4,
	AWAITING_DRIVER: 5,
	CANCELED_DRIVER: 6,
	EXPIRED: 7,
} as const;

export type TRideStatusKey = (typeof RIDE_STATUS)[keyof typeof RIDE_STATUS];

export type TRide = {
	rid_id: number;
	rid_request_time: string;
	rid_start_address?: string;
	rid_end_address?: string;
	rid_end_time?: string;
	rid_approximation?: number;
	rid_total_price?: number;
	tax_id: number;
	rid_start_longitude: number;
	rid_end_longitude?: number;
	rid_rating?: number;
	rid_discount?: number;
	rid_distance?: number;
	rid_car_rating?: number;
	usr_id?: number;
	rid_start_latitude?: number;
	rid_end_latitude: number;
	rid_start_time?;
	rid_status: TRideStatusKey;
	rid_cancel_reason?: number;
	rid_comment?: string;
	rid_adjusted_price?: number;
};

export type TUser = {
	usr_id: number;
	usr_number: string;
};

export type TOffer = {
	off_id: number;
	off_status: number;
	off_reqdate: string;
	off_accdate: string;
	off_discount: number;
	tax_id: number;
};

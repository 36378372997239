import { defineStore } from "pinia";
import { useWindowSize } from "@vueuse/core";
import { useRouter, useRoute } from "vue-router";
import { simpleCrypt, simpleDecrypt } from "@/assets/js/helpers";

export const useDefaultStore = defineStore("main", () => {
	const { width } = useWindowSize();
	const isMobileToggled = ref(false);
	const router = useRouter();
	const route = useRoute();

	const isMobileSize = computed(() => width.value < 768);
	const isMobileView = computed(
		() => isMobileSize.value && isMobileToggled.value,
	);

	watch(isMobileSize, (val) => {
		if (!val) {
			isMobileToggled.value = false;
		}
	});

	function decodePath(name: string) {
		return simpleDecrypt("abc", name);
	}
	function encodePath(name: string) {
		return simpleCrypt("abc", name);
	}
	async function onClickLoginPage() {
		// Used to return user to the previous page
		const currentPageName = String(route.name);

		// Exceptions - Auth pages
		let ppn: string | undefined = encodePath(currentPageName);
		if (route.matched.some((rt) => rt.name === "AuthPage")) {
			ppn = undefined;
		}

		await router.push({
			name: "LoginPage",
			query: {
				ppn,
			},
		});
	}

	async function goToProfilePage() {
		await router.push({
			// name: "SettingsPage",
			// name: "ProfilePage",
			name: "LoginPage",
		});
	}

	return {
		isMobileToggled,
		isMobileView,
		decodePath,
		encodePath,
		onClickLoginPage,
		goToProfilePage,
	};
});

<script setup lang="ts">
import api from "@/api";
import { baseURL } from "@/api/config";
import type { TDriver, TDriverStatus } from "@/assets/js/globalTypes";
import { debounce } from "@/assets/js/helpers";
import { $t } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
definePage({
	name: "TaxiPage",
	meta: {
		requiresLogin: true,
	},
});

const limit = 23;

const drivers = ref<TDriver[]>([]);
const count = ref<number>(0);
const driverStatus = ref<{ type: TDriverStatus; label: string }>({
	type: "all",
	label: $t("app.multiselect.all"),
});
const driverVerified = ref<{
	type: "all" | "verified" | "verified";
	label: string;
}>({
	type: "all",
	label: $t("app.multiselect.all"),
});
const search = ref("");

const route = useRoute();
const router = useRouter();

const multiselectStatusOptions = [
	{
		type: "all",
		label: $t("app.multiselect.all"),
	},
	{
		type: "active",
		label: $t("app.driver.active"),
	},
	{
		type: "inride",
		label: $t("app.driver.in-ride"),
	},
	{
		type: "inactive",
		label: $t("app.driver.inactive"),
	},
];

const multiselectVerifiedOptions = [
	{
		type: "all",
		label: $t("app.multiselect.all"),
	},
	{
		type: "verified",
		label: $t("app.driver.verified"),
	},
	{
		type: "unverified",
		label: $t("app.driver.unverified"),
	},
];

const handleInput = debounce(repopulateDrivers, 300, false);

const pageCount = computed(() => Math.ceil(count.value / limit));
const currentPage = computed(() => Number(route.query.page) || 1);
onMounted(() => {
	getDrivers();
});

async function getDrivers(limit = 23, offset = 0) {
	try {
		const res = await api.getDrivers({
			status: driverStatus.value.type,
			limit,
			offset,
			search: search.value.trim() || null,
			verified: driverVerified.value.type,
		});
		drivers.value = res.data.data.data;
		count.value = res.data.data.meta_info.count;
	} catch (error) {
		console.log(error);
	}
}

function handlePageChange(pageNumber: number) {
	const offset = limit * (pageNumber - 1);
	getDrivers(limit, offset);
}

function repopulateDrivers() {
	router.push({ name: "TaxiPage" });
	getDrivers();
}
</script>

<template>
	<div id="taxi-page">
		<div class="content">
			<h2>{{ $t("app.taxi.title") }}</h2>
			<div class="control-bar">
				<div class="control-bar-item">
					<label>{{ $t("app.driver.status") }}:</label>
					<div class="multiselect-wrap">
						<multiselect-form
							v-model="driverStatus"
							:options="multiselectStatusOptions"
							:valueProp="'type'"
							:slot-text="'label'"
							@select="repopulateDrivers"
						/>
					</div>
				</div>
				<div class="control-bar-item">
					<label>{{ $t("app.driver.verification") }}:</label>
					<div class="multiselect-wrap">
						<multiselect-form
							v-model="driverVerified"
							:options="multiselectVerifiedOptions"
							:valueProp="'type'"
							:slot-text="'label'"
							@select="repopulateDrivers"
						/>
					</div>
				</div>
				<div class="multiselect-wrap">
					<modern-input
						v-model="search"
						:label="''"
						:bind="{
							placeholder: $t('app.labels.search'),
						}"
						@input="handleInput"
					></modern-input>
				</div>
			</div>
			<div
				v-if="drivers.length > 0"
				class="drivers"
			>
				<router-link
					v-for="driver in drivers"
					:key="driver.tax_id"
					:to="'/taxi/' + driver?.tax_id"
				>
					<card-view>
						<template #title>
							<div class="title">
								<div class="avatar">
									<img
										:src="baseURL + driver.image"
										alt=""
									/>
									<div
										class="status"
										:class="{ active: driver.tax_active }"
									></div>
								</div>
								<h3>{{ driver.tax_name }} {{ driver.tax_lastname }}</h3>
							</div>
						</template>
						<template #content>
							<div>
								<ul>
									<li>
										{{ $t("app.driver.car") }}:
										<strong>{{ driver.tax_model }}</strong>
									</li>
									<li>
										{{ $t("app.driver.plates") }}:
										<strong>{{ driver.tax_plates }}</strong>
									</li>
									<li>
										{{ $t("app.driver.remaining-credits") }}:
										<strong>{{ driver?.tax_remaining_credit || 0 }}</strong>
									</li>
									<li v-if="driver.aso_name">
										{{ $t("app.driver.assotiation") }}:
										<strong>{{ driver.aso_name }}</strong>
									</li>
								</ul>
								<p>
									{{
										driver.tax_privilege
											? $t("app.driver.verified")
											: $t("app.driver.unverified")
									}}
								</p>
							</div>
						</template>
					</card-view>
				</router-link>
			</div>
			<div
				v-else
				class="no-result"
			>
				<h1>
					{{ $t("app.labels.noresult") }}
				</h1>
			</div>
			<div class="pagination-wrap">
				<pagination-comp
					:total-pages="pageCount"
					:current-page="currentPage"
					@page-changed="handlePageChange"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.content {
	width: 100%;
	padding: 30px;
	.control-bar {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
		justify-content: center;
		align-items: center;
		gap: 25px;
		.multiselect-wrap {
			width: 200px;
		}
		.control-bar-item {
			display: flex;
			gap: 5px;
		}
	}
	h2 {
		font-size: 1.5rem;
		text-align: center;
		margin-bottom: 10px;
	}
	.drivers {
		display: flex;
		justify-content: space-between;
		column-gap: 10px;
		row-gap: 20px;
		flex-wrap: wrap;
		&::after {
			content: "";
			flex: auto;
		}
	}
	.no-result {
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
	.title {
		display: flex;
		align-items: center;
		gap: 10px;
		h3 {
			font-size: 1.35rem;
		}
	}
	.avatar {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		justify-content: center;
		border: 1px solid $app-accent-color1;
		align-self: center;
		position: relative;
		.status {
			position: absolute;
			bottom: 5px;
			right: 5px;
			border-radius: 50%;
			height: 15px;
			width: 15px;
			background: $error-color;
			&.active {
				background: $success-color;
			}
		}
		img {
			object-fit: cover;
			object-position: center;
		}
	}
	.pagination-wrap {
		position: fixed;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		width: fit-content;
		background: #ffffffd8;
		padding: 5px 10px;
	}
	a {
		@media screen and (width <= 768px) {
			width: 100%;
		}
	}
}
</style>

import api from "@/api";
// import { jwtDecode } from "jwt-decode";
import {
	getLocalStorageReac,
	getSessionStorageReac,
	setLocalStorageReac,
	setSessionStorageReac,
} from "@/assets/js/helpers";
import { useToastStore } from "./toast";
import { baseURL } from "@/api/config";
import type { TUserData } from "@/api/types";

export const useSessionStore = defineStore("session", () => {
	const router = useRouter();
	const userToken = ref("");
	const userData = ref<TUserData | null>();
	const tokenRefreshTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
	const isBusyRenewalToken = ref(false);

	const isLoggedIn = computed(() => !!userToken.value);
	const isUserAdmin = computed(
		() =>
			// userData.value?.rol_name === "admin" ||
			// userData.value?.rol_name === "super-admin",
			true,
	);
	const userPermissions = computed<string[]>(
		() => userData.value?.permissions || [],
	);
	const userImage = computed(() => {
		return getImageUrl(userData.value?.img_id);
	});
	function getImageUrl(imageId?: number | string): string | null {
		if (imageId) {
			return `${baseURL}/users/images-get/${imageId}`;
		}
		return null;
	}

	function saveToken(token: string, isRemember = false) {
		userToken.value = token;
		// userData.value = token ? jwtDecode(token) : null;

		if (token) {
			if (isRemember) {
				setLocalStorageReac("user-token", token);
			} else {
				setSessionStorageReac("user-token", token);
			}
		} else {
			const sesToken = getSessionStorageReac("user-token");
			const locToken = getLocalStorageReac("user-token");
			sesToken.value = null;
			locToken.value = null;
		}
	}
	async function loadSessionToken() {
		const sesToken =
			(getSessionStorageReac("user-token").value as string) || "";
		const locToken = (getLocalStorageReac("user-token").value as string) || "";

		const loadSession = async (token: string) => {
			if (typeof token === "string") {
				saveToken(token);
				if (!tokenRefreshTimeout.value) {
					await setupTokenRenewal(true);
				}
			} else {
				console.error("Invalid token format", token);
			}
		};

		if (sesToken) {
			await loadSession(sesToken);
		} else if (locToken) {
			await loadSession(locToken);
		}
	}

	async function setupTokenRenewal(isRefreshToken = false, debug = true) {
		if (isBusyRenewalToken.value) {
			// console.warn(":: Busy token, exiting");
			return;
		}

		isBusyRenewalToken.value = true;
		tokenRefreshTimeout.value && clearTimeout(tokenRefreshTimeout.value);
		tokenRefreshTimeout.value = null;

		if (isRefreshToken) {
			await renewToken();
		}

		const TIME_TO_REFRESH = 15 * 60 * 1000;
		if (debug) {
			console.log(
				`:: Setup token refresh - ${TIME_TO_REFRESH / 60 / 1000}min`,
				isRefreshToken,
			);
		}
		tokenRefreshTimeout.value = setTimeout(() => {
			void setupTokenRenewal(true);
		}, TIME_TO_REFRESH);
		isBusyRenewalToken.value = false;
	}
	function cancelRenewToken() {
		if (tokenRefreshTimeout.value) {
			clearTimeout(tokenRefreshTimeout.value);
			tokenRefreshTimeout.value = null;
		}
	}
	async function renewToken() {
		if (!userToken.value && tokenRefreshTimeout.value) {
			return;
		}

		try {
			await api.authRenewSession();
			console.log(":: Token refreshed");
		} catch {
			// ignored
		}
	}

	async function setupLogin(
		payload: Parameters<typeof api.authLogin>[0],
		isRemember = false,
	) {
		try {
			const res = await api.authLogin(payload);
			saveToken(res.data.sid, false);
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}
	async function logoutApi(
		showMsg = false,
		shouldRedirect = true,
	): Promise<boolean> {
		const checkRedirectUser = () => {
			if (shouldRedirect) {
				void router.replace("/auth");
			}
		};

		if (!userToken.value) {
			console.warn(":: User already logged out");
			// useToastStore().openToastWarning("User already logged out");
			checkRedirectUser();
			return false;
		}
		try {
			await api.authLogout();
			cancelRenewToken();

			if (showMsg) {
				useToastStore().openToastInfo("Uspešno izlogovan");
			}

			checkRedirectUser();

			return true;
		} catch {
			return false;
		} finally {
			saveToken("", false);
		}
	}

	/**
	 * This function is used to check if the current user has specific permission
	 */
	function can(...keys: string[]) {
		return keys.every((key) => userPermissions.value.includes(key));
	}

	return {
		isLoggedIn,
		isUserAdmin,
		userData,
		userImage,
		can,
		setupLogin,
		saveToken,
		loadSessionToken,
		setupTokenRenewal,
		logoutApi,
		getImageUrl,
	};
});

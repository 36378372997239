<script setup lang="ts">
import api from "@/api";
import type { TDriver, TDriverStatus } from "@/assets/js/globalTypes";
import "@here/maps-api-for-javascript";
import taxiPin from "@/assets/image/driver-icon2.png";
definePage({
	name: "HomePage",
	meta: {
		requiresLogin: true,
	},
});

const platform = ref<H.service.Platform | null>(null);
const maptypes = ref<any>();
const mapContainer = ref(null);
const map = ref<H.Map | null>(null);

const isOpen = ref(true);

const selectedDriver = computed(() => {
	let value = Number(route.query.taxId);
	return isNaN(value) ? null : value || null;
});

const type = ref<TDriverStatus>("all");

const drivers = shallowRef<TDriver[]>([]);
const driverFetchInterval = ref<NodeJS.Timeout | null>(null);

const markerGroup = ref<H.map.Group | null>();
const router = useRouter();
const route = useRoute();

onMounted(() => {
	initMap();
	setupDriverTracking();
});

onBeforeUnmount(() => {
	clearDriverTracking();
});

watch(drivers, () => {
	if (!markerGroup.value || !map.value) {
		return;
	}
	if (drivers.value.length > 0) {
		clearMarkers();
		const icon = new H.map.Icon(taxiPin, { size: { w: 25, h: 25 } });
		markerGroup.value.addObjects(
			drivers.value.map((el) => {
				const marker = new H.map.Marker(
					{ lat: el.tax_latitude, lng: el.tax_longitude },
					{ icon, data: { ...el } },
				);
				marker.addEventListener("tap", (e) => {
					const value = e.target.getData();
					router.push({
						name: "HomePage",
						query: { taxId: value.tax_id },
					});
				});
				return marker;
			}),
		);
	}
});

function clearMarkers() {
	markerGroup.value.removeAll();
}

async function setupDriverTracking() {
	driverFetchInterval.value = setInterval(() => {
		getDrivers(type.value);
	}, 5000);
}

function clearDriverTracking() {
	if (driverFetchInterval.value) {
		clearInterval(driverFetchInterval.value);
	}
	window.removeEventListener("resize", resizeMap);
}

async function getDrivers(type: TDriverStatus) {
	try {
		const res = await api.getDriverLocations({ type });
		drivers.value = res.data.data;
	} catch (e) {
		throw e;
	}
}

function resizeMap() {
	map.value.getViewPort().resize();
}

async function initMap() {
	platform.value = new H.service.Platform({
		apikey: "EqoIMcQ6nbfBTqzVnYUU-Q_pG4BPh4nAq2JOocUhj9s",
	});
	maptypes.value = platform.value.createDefaultLayers({ ppi: 250 });
	map.value = new H.Map(mapContainer.value, maptypes.value.vector.normal.map, {
		pixelRatio: 2,
		zoom: 13,
		center: { lng: 20.4612, lat: 44.8025 },
	});
	map.value.addEventListener(
		"pointermove",
		function (event) {
			if (event.target instanceof H.map.Marker) {
				map.value.getViewPort().element.style.cursor = "pointer";
			} else {
				map.value.getViewPort().element.style.cursor = "auto";
			}
		},
		false,
	);
	new H.mapevents.Behavior(new H.mapevents.MapEvents(map.value));
	markerGroup.value = new H.map.Group();
	map.value.addObject(markerGroup.value);
	window.addEventListener("resize", resizeMap);
}

function handleOverflow(hide: boolean) {
	document.body.style.overflowX = hide ? "hidden" : null;
}

function handleStatisticOpen() {
	isOpen.value = !isOpen.value;
}
</script>

<template>
	<div class="page">
		<div class="content">
			<div
				id="map"
				ref="mapContainer"
			></div>
		</div>
		<div
			class="statistic-wrap"
			:class="{ open: isOpen }"
		>
			<div
				class="statistic-title"
				@mousedown.prevent.stop="handleStatisticOpen"
			>
				<h3>{{ $t("app.statistics.title") }}</h3>
				<svg
					class="faico custom-caret"
					viewBox="0 0 512 512"
					width="12px"
					height="12px"
					:class="{ reverse: isOpen }"
				>
					<path
						fill="currentColor"
						d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7L86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
					></path>
				</svg>
			</div>
			<div class="statistic-content">
				<transition :name="'slide-down'">
					<statistic-quick v-if="isOpen" />
				</transition>
			</div>
		</div>
		<transition
			:name="'slide-in'"
			tag="div"
			@before-enter="handleOverflow(true)"
			@after-enter="handleOverflow(false)"
			@before-leave="handleOverflow(true)"
			@after-leave="handleOverflow(false)"
		>
			<div
				v-if="selectedDriver"
				class="aside"
			>
				<div
					class="close-button"
					@click="router.push({ name: 'HomePage' })"
				>
					<img
						src="@/assets/image/icons/close.svg"
						alt=""
					/>
				</div>
				<driver-component :driver-id="selectedDriver" />
			</div>
		</transition>
	</div>
</template>

<style lang="scss" scoped>
.content {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
#map {
	width: 100%;
	height: 100%;
}
.aside {
	position: fixed;
	right: 0;
	top: 70px;
	bottom: 0;
	width: 15vw;
	min-width: 300px;
	z-index: 30;
	background: #ffffffef;
	transform: translateX(0);
	padding: 0 10px;
	.close-button {
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 20px;
		font-size: 1.5rem;
	}
}
.statistic-wrap {
	position: absolute;
	min-width: 290px;
	min-height: 48px;
	background: #fff;
	opacity: 0.7;
	top: 80px;
	left: 30px;
	transition: opacity 0.2s ease;
	overflow: hidden;
	padding: 8px;
	border-radius: 5px;
	&:hover {
		opacity: 1;
	}
	&.open {
		opacity: 1;
	}
	.statistic-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 32px;
		font-size: 1.1rem;
		font-weight: 600;
		cursor: pointer;
	}
	.statistic-content {
		overflow: hidden;
	}
}
.custom-caret {
	transition: transform 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;

	&.reverse {
		transform: rotateX(180deg);
	}
}
.slide-in-enter-active,
.slide-in-leave-active {
	transition: transform 0.5s ease;
}

.slide-in-enter-from,
.slide-in-leave-to {
	transform: translateX(100%);
	overflow: hidden;
}

.slide-down-enter-active,
.slide-down-leave-active {
	transition:
		transform 0.3s ease,
		opacity 0.3s linear;
	overflow: hidden;
}

.slide-down-enter-from,
.slide-down-leave-to {
	transform: translateY(-100%);
	opacity: 0;
}
.slide-down-enter-to,
.slide-down-leave-from {
	transform: translateY(0);
	opacity: 1;
}
</style>

import { useSessionStore } from "@/store/session";
import { createRouter, createWebHistory } from "vue-router/auto";

const router = createRouter({
	history: createWebHistory(),
});

router.beforeEach((to, from) => {
	if (to.meta.requiresLogin) {
		const isLoggedIn = useSessionStore().isLoggedIn;
		if (isLoggedIn) {
			return true;
		}
		return "/auth/login";
	}
	// Forbid going to auth pages if user is logged in
	const authPage = "AuthPage";
	if (to.matched.some((match) => match.name === authPage)) {
		const isLoggedIn = useSessionStore().isLoggedIn;
		if (isLoggedIn) {
			return from || "/";
		}
	}
	return true;
});

export default router;

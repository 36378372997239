import { axios } from "./config.js";
import type {
	TAuthVerifyCreate,
	TAuthVerifyResend,
	TAxiosGenRes,
	TBasicType,
	TForgotPass,
	TGetDriverLocations,
	TGetDrivers,
	TLoginParam,
	TLoginRes,
	TResetPass,
} from "./types";

export default {
	// Auth
	authLogin: (params: TLoginParam) =>
		axios.post<TLoginRes>("admin/login", params),
	authRegister: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/register", params),
	authRenewSession: () =>
		axios.post<{ data: { data: string } }>("admin/checkSid"),
	authLogout: () => axios.get("admin/logout", { validateStatus: null }),
	authVerifyCreate: (params: TAuthVerifyCreate) =>
		axios.post<TLoginRes>("auth/verify", params),
	authVerifyResend: (params: TAuthVerifyResend) =>
		axios.put("auth/verify", params),
	authForgot: (params: TForgotPass) =>
		axios.post("auth/forgot-password", params),
	authResetPass: (params: TResetPass) =>
		axios.put("auth/forgot-password", params),
	getDrivers: (params: TGetDrivers) => axios.get("admin/taxi", { params }),

	getDriverLocations: (params: TGetDriverLocations) =>
		axios.get("admin/get-driver-locations", { params }),
	getDriverInfo: (params: { tax_id: number }) =>
		axios.get("admin/taxi/" + params.tax_id),
	editDriver: (params: any) => axios.put("admin/taxi/" + params.tax_id, params),
	banDriver: (tax_id: number) => axios.delete("admin/taxi/" + tax_id),
	getStatisticsQuick: () => axios.get("admin/statistics/quick"),
	getDriverStatistics: (tax_id: number) =>
		axios.get("admin/taxi/" + tax_id + "/statistics"),
	downloadDocument: (file_id: number) =>
		axios.get("admin/document/" + file_id, { responseType: "blob" }),
	getRides: (params: TGetDrivers) => axios.get("admin/rides", { params }),
	getRideInfo: (rid_id: number) => axios.get("admin/rides/" + rid_id),
};

<script setup lang="ts">
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";
import type { TDriver, TRide } from "@/assets/js/globalTypes";
import { rideStatusText } from "@/assets/js/helpers";
import { debounce } from "@/assets/js/helpers";
import { $t } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
definePage({
	name: "RidesPage",
	meta: {
		requiresLogin: true,
	},
});

const limit = 23;

const rides = ref<(TRide & Partial<TDriver>)[]>([]);
const count = ref<number>(0);

const search = ref("");

const route = useRoute();
const router = useRouter();

// const multiselectStatusOptions = [
// 	{
// 		type: "all",
// 		label: $t("app.multiselect.all"),
// 	},
// 	{
// 		type: "active",
// 		label: $t("app.driver.active"),
// 	},
// 	{
// 		type: "inride",
// 		label: $t("app.driver.in-ride"),
// 	},
// 	{
// 		type: "inactive",
// 		label: $t("app.driver.inactive"),
// 	},
// ];

const handleInput = debounce(repopulateDrivers, 300, false);

const pageCount = computed(() => Math.ceil(count.value / limit));
const currentPage = computed(() => Number(route.query.page) || 1);
onMounted(() => {
	getRides();
});

async function getRides(limit = 23, offset = 0) {
	try {
		const res = await api.getRides({
			limit,
			offset,
			search: search.value.trim() || null,
		});
		rides.value = res.data.data.data;
		count.value = res.data.data.meta_info.count;
	} catch (error) {
		console.log(error);
	}
}

function handlePageChange(pageNumber: number) {
	const offset = limit * (pageNumber - 1);
	getRides(limit, offset);
}

function repopulateDrivers() {
	router.push({ name: "RidesPage" });
	getRides();
}
</script>

<template>
	<div id="ride-page">
		<div class="content">
			<h2>{{ $t("app.rides.title") }}</h2>
			<div class="control-bar">
				<!-- <div class="control-bar-item">
					<label>{{ $t("app.driver.status") }}:</label>
					<div class="multiselect-wrap">
						<multiselect-form
							v-model="rideStatus"
							:options="multiselectStatusOptions"
							:valueProp="'type'"
							:slot-text="'label'"
							@select="repopulateDrivers"
						/>
					</div>
				</div>
				<div class="control-bar-item">
					<label>{{ $t("app.driver.verification") }}:</label>
					<div class="multiselect-wrap">
						<multiselect-form
							v-model="driverVerified"
							:options="multiselectVerifiedOptions"
							:valueProp="'type'"
							:slot-text="'label'"
							@select="repopulateDrivers"
						/>
					</div>
				</div> -->
				<div class="multiselect-wrap">
					<modern-input
						v-model="search"
						:label="''"
						:bind="{
							placeholder: $t('app.labels.search'),
						}"
						@input="handleInput"
					></modern-input>
				</div>
			</div>
			<div
				v-if="rides.length > 0"
				class="rides"
			>
				<router-link
					v-for="ride in rides"
					:key="ride.rid_id"
					:to="'/rides/' + ride?.rid_id"
				>
					<card-view>
						<template #title>
							<h3>Id: {{ ride.rid_id }}</h3>
						</template>
						<template #content>
							<div class="ride-info">
								<p>
									{{
										DateInstance.getLocalTimeFromISO(
											ride.rid_request_time,
											"HH:mm DD.MM.YYYY",
										)
									}}
								</p>
								<p>
									{{ $t("app.driver.status") }}:
									<strong>{{ rideStatusText(ride.rid_status) }}</strong>
								</p>
								<div v-if="ride.rid_status === 2">
									<p>
										{{ $t("app.rides.price") }}:
										<strong>{{ ride.rid_total_price }}RSD</strong>
									</p>
								</div>
							</div>
							<div
								v-if="ride.tax_id"
								class="driver-info"
							>
								<p>
									{{ $t("app.driver.driver") }}:
									<strong>
										{{ ride.tax_name }}
										{{ ride.tax_lastname }}
									</strong>
								</p>
								<div>
									<ul>
										<li>
											{{ $t("app.driver.car") }}:
											<strong>{{ ride.tax_model }}</strong>
										</li>
										<li>
											{{ $t("app.driver.plates") }}:
											<strong>{{ ride.tax_plates }}</strong>
										</li>
										<li v-if="ride.aso_name">
											{{ $t("app.driver.assotiation") }}:
											<strong>{{ ride.aso_name }}</strong>
										</li>
									</ul>
								</div>
							</div>
						</template>
					</card-view>
				</router-link>
			</div>
			<div
				v-else
				class="no-result"
			>
				<h1>
					{{ $t("app.labels.noresult") }}
				</h1>
			</div>
			<div class="pagination-wrap">
				<pagination-comp
					:total-pages="pageCount"
					:current-page="currentPage"
					@page-changed="handlePageChange"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.content {
	width: 100%;
	padding: 30px;
	.control-bar {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
		justify-content: center;
		align-items: center;
		gap: 25px;
		.multiselect-wrap {
			width: 200px;
		}
		.control-bar-item {
			display: flex;
			gap: 5px;
		}
	}
	h2 {
		font-size: 1.5rem;
		text-align: center;
		margin-bottom: 10px;
	}
	.rides {
		display: flex;
		justify-content: space-between;
		column-gap: 10px;
		row-gap: 20px;
		flex-wrap: wrap;
		&::after {
			content: "";
			flex: auto;
		}
	}
	.no-result {
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
	.title {
		display: flex;
		align-items: center;
		gap: 10px;
		h3 {
			font-size: 1.35rem;
		}
	}
	.avatar {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		justify-content: center;
		border: 1px solid $app-accent-color1;
		align-self: center;
		position: relative;
		.status {
			position: absolute;
			bottom: 5px;
			right: 5px;
			border-radius: 50%;
			height: 15px;
			width: 15px;
			background: $error-color;
			&.active {
				background: $success-color;
			}
		}
		img {
			object-fit: cover;
			object-position: center;
		}
	}
	.pagination-wrap {
		position: fixed;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		width: fit-content;
		background: #ffffffd8;
		padding: 5px 10px;
	}
	a {
		@media screen and (width <= 768px) {
			width: 100%;
		}
	}
}
</style>

<script setup lang="ts">
definePage({
	name: "RegisterPage",
	meta: {
		name: "Register",
	},
});
</script>

<template>
	<div id="register-page">
		<div class="image-section">
			<img
				alt="placeholder-image"
				src=""
			/>
		</div>
		<div class="comp-section">
			<register-comp></register-comp>
		</div>
	</div>
</template>

<style lang="scss" scoped>
#register-page {
	display: flex;
	flex-direction: column;
	gap: 20px 170px;
	height: 100%;
	width: 100%;

	.image-section,
	.comp-section {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.image-section {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;

		.title {
			font-family: Anton, sans-serif;
			font-size: 60px;
			font-style: normal;
			font-weight: 400;
		}

		.desc {
			font-family: "Open Sans", sans-serif;
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		img {
			width: 100%;
			max-width: 900px;
		}
	}

	@media screen and (width > 768px) {
		flex-direction: row;

		.image-section {
			flex-basis: 80%;
		}
		.comp-section {
			min-width: 400px;

			> * {
				width: 100%;
			}
		}
	}
}
</style>

<script setup lang="ts">
import api from "@/api";
import {
	// RIDE_STATUS,
	type TDriver,
	type TOffer,
	type TRide,
	type TUser,
	// type TRideStatusKey,
} from "@/assets/js/globalTypes";
import { useRoute } from "vue-router";
import startIcon from "@/assets/image/start.png";
import finishIcon from "@/assets/image/finish.png";
// import taxiPin from "@/assets/image/driver-icon2.png";

import "@here/maps-api-for-javascript";

import { DateInstance } from "@/assets/js/dateHelper";
import { baseURL } from "@/api/config";
import { rideStatusText } from "@/assets/js/helpers";

definePage({
	name: "SingleRidePage",
	meta: {
		requiresLogin: true,
	},
});

const route = useRoute();

const rid_id = computed(() => Number(route.params.id) || 0);

watch(rid_id, getRideInfo, { immediate: true });

const ride = ref<TRide & Partial<TDriver> & Partial<TUser>>();
const offers = ref<(TOffer & Partial<TDriver>)[]>();

const platform = ref<H.service.Platform | null>(null);
const maptypes = ref<any>();
const mapContainer = ref(null);
const map = ref<H.Map | null>(null);
const markerGroup = ref<H.map.Group>();

function resizeMap() {
	map.value.getViewPort().resize();
}

async function initMap() {
	platform.value = new H.service.Platform({
		apikey: "EqoIMcQ6nbfBTqzVnYUU-Q_pG4BPh4nAq2JOocUhj9s",
	});
	maptypes.value = platform.value.createDefaultLayers({ ppi: 250 });
	map.value = new H.Map(mapContainer.value, maptypes.value.vector.normal.map, {
		pixelRatio: 2,
		zoom: 13,
		center: { lng: 20.4612, lat: 44.8025 },
	});
	map.value.addEventListener(
		"pointermove",
		function (event) {
			if (event.target instanceof H.map.Marker) {
				map.value.getViewPort().element.style.cursor = "pointer";
			} else {
				map.value.getViewPort().element.style.cursor = "auto";
			}
		},
		false,
	);
	new H.mapevents.Behavior(new H.mapevents.MapEvents(map.value));
	markerGroup.value = new H.map.Group();
	map.value.addObject(markerGroup.value);
	window.addEventListener("resize", resizeMap);
}
onMounted(() => initMap());

let first = true;
watch(
	() => [ride.value, map.value],
	() => {
		if (ride.value && map.value && first) {
			setTimeout(addMarkers, 1000);
			first = false;
		}
	},
	{ immediate: true },
);

async function getRideInfo() {
	try {
		const res = await api.getRideInfo(rid_id.value);
		ride.value = res.data.data.data;
		offers.value = res.data.data.offers;
	} catch (e) {
		console.log(e);
	}
}

function addMarkers() {
	const start_icon = new H.map.Icon(startIcon, { size: { w: 25, h: 25 } });
	const start_marker = new H.map.Marker(
		{ lat: ride.value.rid_start_latitude, lng: ride.value.rid_start_longitude },
		{ icon: start_icon, data: {} },
	);

	markerGroup.value.addObject(start_marker);

	if (ride.value.rid_end_latitude) {
		const end_icon = new H.map.Icon(finishIcon, { size: { w: 25, h: 25 } });
		const end_marker = new H.map.Marker(
			{ lat: ride.value.rid_end_latitude, lng: ride.value.rid_end_longitude },
			{ icon: end_icon, data: {} },
		);
		markerGroup.value.addObject(end_marker);
	}

	//todo - work on tracking driver
	// if (
	// 	ride.value.tax_id &&
	// 	(
	// 		[
	// 			RIDE_STATUS.ACTIVE,
	// 			RIDE_STATUS.AWAITING_DRIVER,
	// 			RIDE_STATUS.AWAITING_USER,
	// 		] as Partial<TRideStatusKey[]>
	// 	).includes(ride.value.rid_status)
	// ) {
	// 	const icon = new H.map.Icon(taxiPin, { size: { w: 25, h: 25 } });
	// 	const marker = new H.map.Marker(
	// 		{ lat: ride.value.tax_latitude, lng: ride.value.tax_longitude },
	// 		{ icon, data: {} },
	// 	);
	// 	markerGroup.value.addObject(marker);
	// }
	const bounds = markerGroup.value.getBoundingBox();
	map.value.getViewModel().setLookAtData({ bounds });
	map.value.setZoom(map.value.getZoom() - 0.5, true);
}
</script>

<template>
	<div id="rides-page-single">
		<div class="ride-wrap">
			<div
				id="map"
				ref="mapContainer"
			></div>
			<div class="ride-content">
				<p>
					{{ $t("app.user.phone") }}:
					<strong>{{ ride?.usr_number }}</strong>
				</p>
				<p>
					{{ $t("app.driver.status") }}:
					<strong>{{ rideStatusText(ride?.rid_status) }}</strong>
				</p>
				<p>
					{{ $t("app.rides.start-address") }}
					<strong>
						{{ ride?.rid_start_address }}
					</strong>
				</p>
				<p v-if="ride?.rid_end_address && ride.rid_end_address.trim()">
					{{ $t("app.rides.end-address") }}:
					<strong>
						{{ ride?.rid_end_address.trim() }}
					</strong>
				</p>
				<p>
					{{ $t("app.rides.requested-at") }}:
					<strong>
						{{
							DateInstance.getLocalTimeFromISO(
								ride?.rid_request_time,
								"HH:mm DD.MM.YY",
							)
						}}
					</strong>
				</p>
				<p v-if="ride?.rid_end_time">
					{{ $t("app.rides.ended-at") }}:
					<strong>
						{{
							DateInstance.getLocalTimeFromISO(
								ride.rid_end_time,
								"HH:mm DD.MM.YY",
							)
						}}
					</strong>
				</p>
				<p v-if="ride?.rid_distance">
					{{ $t("app.rides.distance") || 0 }}:
					<strong>{{ ride?.rid_distance }}</strong>
				</p>
				<p v-if="ride?.rid_discount">
					{{ $t("app.rides.discount") }}:
					<strong>{{ ride?.rid_discount + "%" || 0 }}</strong>
				</p>
				<p v-if="ride?.rid_total_price">
					{{ $t("app.rides.total-price") }}:
					<strong>{{ ride?.rid_total_price }}RSD</strong>
				</p>
				<p v-if="ride?.rid_adjusted_price">
					{{ $t("app.rides.adjusted-price") }}:
					<strong>{{ ride?.rid_adjusted_price }}RSD</strong>
				</p>
				<p v-if="ride?.rid_approximation">
					{{ $t("app.rides.approximation") }}:
					<strong>{{ ride?.rid_approximation }}min</strong>
				</p>
				<p v-if="ride?.rid_rating">
					{{ $t("app.rides.rating") }}:
					<strong>{{ ride?.rid_rating }}</strong>
				</p>
				<p v-if="ride?.rid_car_rating">
					{{ $t("app.rides.car-rating") }}:
					<strong>{{ ride?.rid_car_rating }}</strong>
				</p>
			</div>
		</div>
		<div class="driver-wrap">
			<div
				v-if="ride?.tax_id"
				class="content"
			>
				<router-link
					:to="{ name: 'SingleTaxiPage', params: { id: ride?.tax_id } }"
				>
					<div class="avatar-wrap">
						<div class="item">
							<h2>{{ ride?.tax_name }} {{ ride.tax_lastname }}</h2>
							<div class="image-wrap">
								<img
									:src="baseURL + ride?.image"
									alt=""
								/>
							</div>
						</div>
						<div class="item">
							<h2>{{ ride?.tax_model }}</h2>
							<div class="image-wrap">
								<img
									:src="baseURL + ride?.tax_car_image"
									alt=""
								/>
							</div>
						</div>
					</div>
				</router-link>
				<section class="driver-content">
					<p>
						{{ $t("app.auth.email") }}:
						<strong>{{ ride?.tax_email }}</strong>
					</p>
					<p>
						{{ $t("app.driver.assotiation") }}:
						<strong>{{ ride?.aso_name }}</strong>
					</p>
					<p>
						{{ $t("app.driver.phone") }}:
						<strong>{{ ride?.tax_phone }}</strong>
					</p>
					<p>
						{{ $t("app.driver.remaining-credits") }}:
						<strong>{{ ride?.tax_remaining_credit || 0 }}</strong>
					</p>
				</section>
			</div>
		</div>
		<div class="offer-wrap">
			<h2>{{ $t("app.offers.title") }}</h2>
			<div class="offer-content">
				<router-link
					v-for="offer in offers"
					:key="offer.off_id"
					:to="{ name: 'SingleTaxiPage', params: { id: offer.tax_id } }"
					class="offer"
				>
					<card-view>
						<template #title>
							<div class="title">
								<h3>{{ offer.tax_name }} {{ offer.tax_lastname }}</h3>
							</div>
						</template>
						<template #content>
							<div class="content">
								<ul>
									<li v-if="offer.off_accdate">
										{{ $t("app.offers.accepted") }}:
										{{
											DateInstance.getLocalTimeFromISO(
												offer.off_accdate,
												"HH:mm DD.MM.YY",
											)
										}}
									</li>
									<li v-else>{{ $t("app.offers.not-accepted") }}</li>
									<li>
										{{ $t("app.rides.discount") }}:
										<strong>{{ offer.off_discount + "%" || 0 }}</strong>
									</li>
								</ul>
							</div>
						</template>
					</card-view>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
#rides-page-single {
	display: grid;
	height: 100%;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, auto);
	gap: 20px;
	grid-template-areas:
		"ride-wrap driver-wrap"
		"ride-wrap offer-wrap";
	h2 {
		font-size: 1.3rem;
		font-weight: 600;
	}
	#map {
		width: 100%;
		height: 60%;
		min-height: 300px;
	}
	.ride-wrap {
		grid-area: ride-wrap;
		.ride-content {
			padding: 10px;
			font-size: 1.1rem;
		}
	}
	.driver-wrap {
		grid-area: driver-wrap;
		padding: 10px;
		.avatar-wrap {
			display: flex;
			gap: 10px;
			img {
				width: 150px;
				height: 150px;
				object-fit: cover;
			}
		}
		.driver-content {
			padding: 10px 0;
			font-size: 1.1rem;
		}
	}
	.offer-wrap {
		grid-area: offer-wrap;
		padding: 7px;
		.offer-content {
			display: flex;
			justify-content: space-between;
			column-gap: 10px;
			row-gap: 20px;
			flex-wrap: wrap;
			&::after {
				content: "";
				flex: auto;
			}
		}
		.title {
			display: flex;
			align-items: center;
			gap: 10px;
			h3 {
				font-size: 1.35rem;
			}
		}
	}

	@media screen and (width < 768px) {
		grid-template-areas:
			"ride-wrap"
			"driver-wrap"
			"offer-wrap";
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, auto);
	}
}
</style>

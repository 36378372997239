import _definePage_default_0 from 'D:/projects/js/taxi-admin-panel/src/pages/index/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from 'D:/projects/js/taxi-admin-panel/src/pages/[...path].vue?definePage&vue'
import _definePage_default_3 from 'D:/projects/js/taxi-admin-panel/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_4 from 'D:/projects/js/taxi-admin-panel/src/pages/auth/register.vue?definePage&vue'
import _definePage_default_5 from 'D:/projects/js/taxi-admin-panel/src/pages/auth/reset-pass.vue?definePage&vue'
import _definePage_default_6 from 'D:/projects/js/taxi-admin-panel/src/pages/auth.vue?definePage&vue'
import _definePage_default_7 from 'D:/projects/js/taxi-admin-panel/src/pages/rides/index.vue?definePage&vue'
import _definePage_default_8 from 'D:/projects/js/taxi-admin-panel/src/pages/rides/[id].vue?definePage&vue'
import _definePage_default_9 from 'D:/projects/js/taxi-admin-panel/src/pages/statistics/index.vue?definePage&vue'
import _definePage_default_10 from 'D:/projects/js/taxi-admin-panel/src/pages/taxi/index.vue?definePage&vue'
import _definePage_default_11 from 'D:/projects/js/taxi-admin-panel/src/pages/taxi/[id].vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('D:/projects/js/taxi-admin-panel/src/pages/index.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'HomePage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/index/index.vue'),
        /* no children */
      },
  _definePage_default_0
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'CatchAllPage',
    component: () => import('D:/projects/js/taxi-admin-panel/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/auth',
    name: 'AuthPage',
    component: () => import('D:/projects/js/taxi-admin-panel/src/pages/auth.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'LoginPage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'register',
        name: 'RegisterPage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/auth/register.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
  _mergeRouteRecord(
      {
        path: 'reset-pass',
        name: 'ResetPass',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/auth/reset-pass.vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  _definePage_default_6
  ),
  {
    path: '/rides',
    name: '/rides',
    component: () => import('D:/projects/js/taxi-admin-panel/src/pages/rides.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'RidesPage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/rides/index.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'SingleRidePage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/rides/[id].vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  {
    path: '/statistics',
    name: '/statistics',
    component: () => import('D:/projects/js/taxi-admin-panel/src/pages/statistics.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'StatisticsPage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/statistics/index.vue'),
        /* no children */
      },
  _definePage_default_9
  )
    ],
  },
  {
    path: '/taxi',
    name: '/taxi',
    component: () => import('D:/projects/js/taxi-admin-panel/src/pages/taxi.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'TaxiPage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/taxi/index.vue'),
        /* no children */
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'SingleTaxiPage',
        component: () => import('D:/projects/js/taxi-admin-panel/src/pages/taxi/[id].vue'),
        /* no children */
      },
  _definePage_default_11
  )
    ],
  }
]
